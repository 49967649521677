import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { cond, stubTrue, constant } from "lodash";
import getVideoId from "get-video-id";
import BlockContent from "@sanity/block-content-to-react";
import { getUrlFor } from "../utils/imageUtils";
import AlsoRead from "../components/AlsoRead";
import LinkWithArrow from "./LinkWithArrow";
import LinkButton from "./LinkButton";
import styles from "./PortableText.module.scss";

const BlockRenderer = props => {
  const { style = "normal" } = props.node;

  if (style === "title") {
    return <h2 className={styles.title}>{props.children}</h2>;
  } else if (style === "subtitle") {
    return <h3 className={styles.subtitle}>{props.children}</h3>;
  } else if (style === "intertitle") {
    return <h3 className={styles.intertitle}>{props.children}</h3>;
  } else if (style === "normal") {
    return <p className={styles.paragraph}>{props.children}</p>;
  }

  return BlockContent.defaultSerializers.types.block(props);
};

export const ContentImageRenderer = ({ node }) => {
  const { caption, alt = "" } = node;
  const imgUrl =
    node &&
    getUrlFor(node)
      .width(1075)
      .fit("min")
      .url();

  if (imgUrl) {
    return (
      <figure className={styles.image}>
        <img src={imgUrl} alt={alt} />
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    );
  }
  return null;
};

export const ContentVideoRenderer = ({ node }) => {
  const { asset, thumbnail } = node;
  const thumbnailUrl =
    thumbnail &&
    getUrlFor(thumbnail)
      .width(720)
      .height(405)
      .fit("crop")
      .url();

  if (asset) {
    return (
      <div className={styles.video}>
        <video width="100%" controls poster={thumbnailUrl}>
          <source src={asset.url} type="video/mp4" />
          <track default kind="captions" srcLang="fr" src="" />
        </video>
      </div>
    );
  }
  return null;
};

export const VideoEmbedRenderer = ({ node }) => {
  const { id, service } = getVideoId(node.url);

  if (id) {
    if (service === "vimeo") {
      return (
        <div className={styles.embedvideo}>
          <iframe
            src={`https://player.vimeo.com/video/${id}`}
            frameBorder="0"
            title="Video"
            allow="fullscreen"
            allowFullScreen
          />
        </div>
      );
    }

    if (service === "youtube") {
      return (
        <div className={styles.embedvideo}>
          <iframe
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder="0"
            title="Video"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
  }
};
export const QuoteRenderer = props => {
  const { author, text } = props.node;

  if (author) {
    return (
      <div className={styles.citation}>
        <blockquote>{text}</blockquote>
        <div>— {author}</div>
      </div>
    );
  }

  return (
    <div className={styles.quote}>
      <blockquote>{text}</blockquote>
    </div>
  );
};

export const InternalLinkRenderer = ({ mark, children }) => {
  const to = `/${mark.reference.slug.current}/${
    mark.anchor ? "#" + mark.anchor : ""
  }`;

  if (mark.withArrow) {
    return <LinkWithArrow to={to}>{children}</LinkWithArrow>;
  }
  return <Link to={to}>{children}</Link>;
};

export const ExternalLinkRenderer = ({ mark, children }) => {
  const target = mark.blank ? "_blank" : "_self";
  const rel = target === "_blank" ? "noopener noreferrer" : "";
  return (
    <a href={mark.href} target={target} rel={rel}>
      {children}
    </a>
  );
};

export const TwoColumnsRenderer = ({ node }) => {
  const { title, image, text } = node;
  const { alt = "" } = image || {};
  const imgUrl =
    image &&
    getUrlFor(image)
      .width(345)
      .fit("min")
      .url();

  return (
    <div className={styles.columns}>
      <div className={styles.columnsImage}>
        <img src={imgUrl} alt={alt} />
      </div>
      <div className={styles.columnsBody}>
        {title && <h4 className={styles.columnsTitle}>{title}</h4>}
        <BlockContent
          blocks={text}
          serializers={{
            types: {
              quote: QuoteRenderer,
              block: BlockRenderer,
            },
            marks: {
              internalLink: InternalLinkRenderer,
            },
          }}
        />
      </div>
    </div>
  );
};

const LatestRefsRenderer = ({ node }) => {
  return (
    <div className={styles.latestRefs}>
      <AlsoRead title={node.title} posts={node.refs}></AlsoRead>
    </div>
  );
};

const ClientLogosRenderer = ({ node }) => {
  return (
    <div className={styles.clientLogos}>
      {node.clients.map(({ thumbImage, title }) => {
        const imgUrl = getUrlFor(thumbImage)
          .width(180)
          .fit("min")
          .url();
        return (
          <div key={title} className={styles.clientLogosItem}>
            <img src={imgUrl} alt={title} />
          </div>
        );
      })}
    </div>
  );
};

const BlockLinkRenderer = ({ node }) => {
  const {
    isExternal,
    url,
    blank,
    reference,
    label,
    isButton,
    title,
    anchor,
  } = node;
  const target = blank ? "_blank" : "_self";
  const rel = target === "_blank" ? "noopener noreferrer" : "";

  if (isExternal) {
    if (isButton) {
      return (
        <div className={`${styles.blockLink} ${styles.blockLinkButton}`}>
          <LinkButton href={url} target={target} title={title}>
            {label}
          </LinkButton>
        </div>
      );
    }
    return (
      <div className={styles.blockLink}>
        <a href={url} target={target} title={title} rel={rel}>
          {label}
        </a>
      </div>
    );
  }

  const isOfType = ofType => type => type === ofType;
  const getUrlPrefix = cond([
    [isOfType("publication"), constant("publications/")],
    [isOfType("project"), constant("projets/")],
    [stubTrue, constant("")],
  ]);
  const to = `${getUrlPrefix(reference._type)}${reference.slug.current}/${
    anchor ? "#" + anchor : ""
  }`;

  if (isButton) {
    return (
      <div className={styles.blockLink}>
        <LinkButton to={to}>{label}</LinkButton>
      </div>
    );
  }

  return (
    <div className={styles.blockLink}>
      <LinkWithArrow to={to}>{label}</LinkWithArrow>
    </div>
  );
};

const defaultSerializers = {
  types: {
    contentImage: ContentImageRenderer,
    contentVideo: ContentVideoRenderer,
    videoEmbed: VideoEmbedRenderer,
    quote: QuoteRenderer,
    twoColumns: TwoColumnsRenderer,
    block: BlockRenderer,
    latestRefs: LatestRefsRenderer,
    clientLogos: ClientLogosRenderer,
    blockLink: BlockLinkRenderer,
  },
  list: ({ children }) => <ul className={styles.list}>{children}</ul>,
  marks: {
    internalLink: InternalLinkRenderer,
    externalLink: ExternalLinkRenderer,
  },
};

const PortableText = ({ blocks }) => {
  return (
    <BlockContent
      blocks={blocks}
      serializers={defaultSerializers}
      projectId="w3tlk96v"
      dataset="production"
    />
  );
};

PortableText.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default PortableText;
